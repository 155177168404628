import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import Keycloak, { KeycloakProfile } from 'keycloak-js';
import { LoggerService } from '@services/log/logger.service';
import { environment } from '@environments/environment';
import { NavigationService } from '../navigation/navigation.service';
import { FoODataUserGateway } from '@app/core/adapter/fo/fo-oData-user.gateway';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private readonly logger: LoggerService = inject(LoggerService);
  private readonly navigationService: NavigationService = inject(NavigationService);
  private readonly foODataUserGateway: FoODataUserGateway = inject(FoODataUserGateway);
  private readonly keycloak: Keycloak = inject(Keycloak);

  public userProfile: KeycloakProfile | null = null;

  async login(): Promise<void> {
    try {
      await this.keycloak.login();
      await this.updateLogin();
    } catch (error) {
      this.logger.error('Error logging in:' + error);
    }
  }

  /**
   * Logs the user out.
   * @returns A promise that resolves once the logout process is complete.
   */
  async logout(): Promise<void> {
    try {
      await this.keycloak.logout({
        redirectUri: window.location.origin + '/'
      });
      await this.updateLogin();
    } catch (error) {
      this.logger.error('Error logging out:'+ error);
    }
  }

  /**
   * Updates the login status and user profile information.
   * @returns A promise that resolves once the login status and user profile are updated.
   */
  async updateLogin(): Promise<void> {
    try {
      if (this.keycloak.authenticated) {
        this.userProfile = await this.keycloak.loadUserProfile();
        this.logger.info(`Token Is Expired: ${this.keycloak.isTokenExpired()}`);

        if (this.keycloak.resourceAccess) {
          this.navigationService.setUserRoles(this.keycloak.resourceAccess[environment.keycloak.initconfig.clientId].roles);//need to be set for getTopUserRole
        }
        this.navigationService.setUserProfilesFromKc(this.userProfile);

        if (!environment.inMemory) {
          if ((this.userProfile?.email) != null) {
            await this.retrieveUserAndCustomerInfo(this.userProfile.email);
          }
        }
      } else {
        this.userProfile = null;
        this.navigationService.resetUserAndCustomerInfo();
      }
    } catch (error) {
      this.logger.error('Error updating login:'+ error);
    }
  }

    /**
   * Retrieves customer information based on the user's email.
   * @param email - The email of the user.
   * @returns A promise that resolves once the customer information is retrieved.
   */
    private async retrieveUserAndCustomerInfo(email: string): Promise<void> {
      try {
        const user = await firstValueFrom(this.foODataUserGateway.getCurrentUser(email));
        if (user == null) {
          this.logger.error('User not found');
          return;
        }
  
        // check if it's really need or delete here & fields & binding in the mappers
        this.navigationService.setRegisterNumber(user.registerNumber);
        this.navigationService.setUserPersonRef(user.person.ref);
        this.navigationService.setUserDefaultDataAreaId(user.defaultDataAreaId);
        if(user.defaultDataAreaId === environment.fo.supplierCompany) {
          this.navigationService.setUserLogisticId(user.logisticEmployeeId) ;
        }
        this.navigationService.setUserDefaultDataAreaId(user.defaultDataAreaId);
        //mandatory to set
        if (user.customerProfiles !== null) {
          this.navigationService.setUserCustomerProfiles(user.customerProfiles);
        }
  
      } catch (error) {
        this.logger.error('Error retrieving employee and client info:'+ error);
      }
    }

    public async checkLoggingStatus(): Promise<void> {
      if (this.keycloak.isTokenExpired()) {
        this.logger.info('Token expired');
        await this.login();
      }
    }
  
}
