import { type Observable, mergeMap, of, switchMap } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { UserGateway } from '@core/port/user-gateway';
import { LoggerService } from '@app/core/services/log/logger.service';
import { FoEmployeeService } from '@services/finops/client-api/fo-employee.service';
import { FoCustomerService } from '@services/finops/client-api/fo-customer.service';
import { generateCustomerFromFoCustomerV3Dto, getDefaultUser, mapEmployeeDtoToUser } from '@app/core/adapter/fo/fo-oData-mappers';
import { type User } from '@models/viewModels/user';
import { type ODataArrayResult } from '@models/dto/odata-result';
import { type ResquestableFoEmployeesV2Dto } from '@models/dto/fo/fo.employeesV2.dto';
import { type RequestableFoCustomersV3Dto } from '@models/dto/fo/fo.customersV3.dto';

@Injectable({ providedIn: 'root' })
export class FoODataUserGateway extends UserGateway {
  private readonly logger = inject(LoggerService);
  private readonly foCustomerService = inject(FoCustomerService);
  private readonly foEmployeeService = inject(FoEmployeeService);

  /**
   * Get the current user by mail
   * In 2 steps:
   * 1. Get the employee by mail
   * 2. Get the customers by employee party number
   * @usecase : loggin user
   * @param mail
   * @returns An observable that emits a User object.
   */
  override getCurrentUser (mail: string): Observable<User> {
    const user = getDefaultUser(); // Create a new user object
    // Retrieve first employee dirparty by mail
    return this.foEmployeeService.getSomeEmployeesByEmail$(mail).pipe(
      switchMap((result: ODataArrayResult<ResquestableFoEmployeesV2Dto>) => {
        if (result.value.length === 0) {
          this.logger.displayObjectError(result,'employee is not found:');
          throw new Error('Employee not found'); // throw error if employee is not found
        }
        /*Assignement management : To prepare  - Parcour de prélèvement :
        recup  SPLRecId de employee dans navigation service pour positionnement sur le parcour de prélèvement */
        this.logger.displayObjectInfo(result,'employee is found:');
        mapEmployeeDtoToUser(user, result.value[0]);
        this.logger.displayObjectDebug(user,'DEBUG user');
        const getCustomers$ = this.foCustomerService.getSomeCustomersByParty$(result.value[0].PartyNumber);

        return getCustomers$.pipe(
          mergeMap((customersResult: ODataArrayResult<RequestableFoCustomersV3Dto>) => {
            if (customersResult.value.length === 0) {
              this.logger.displayObjectInfo(customersResult,'no customers found:');
              user.customerProfiles = []; /* Reset customerProfiles array if no customers found */
              return of(user); // return user without customers
            }
            // Map the customers and assign them to a new array
            user.customerProfiles = customersResult.value.map((dto: RequestableFoCustomersV3Dto) => generateCustomerFromFoCustomerV3Dto(dto));
            this.logger.info('getCurrentUser : end');
            return of(user); // return user with customers
          })
        );
      })
    );
  }
}
